.ant-message {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: #ffffff;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	position: fixed;
	top: 32px;
	left: 0;
	z-index: 5000;
	width: 100%;
	pointer-events: none;
}
.ant-message-notice {
	padding: 8px;
	text-align: center;
}
.ant-message-notice:first-child {
	margin-top: -8px;
}
.ant-message-notice-content {
	display: inline-block;
	padding: 10px 16px;
	background: #2c2c2c;
	border-radius: 4px;
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
	pointer-events: all;
}
.ant-message-success .anticon {
	color: #52c41a;
}
.ant-message-error .anticon {
	color: #ff4d4f;
}
.ant-message-warning .anticon {
	color: #faad14;
}
.ant-message-info .anticon,
.ant-message-loading .anticon {
	color: #1890ff;
}
.ant-message .anticon {
	position: relative;
	top: 1px;
	margin-right: 8px;
	font-size: 16px;
}
.ant-message-notice.move-up-leave.move-up-leave-active {
	animation-name: MessageMoveOut;
	animation-duration: 0.3s;
}
@keyframes MessageMoveOut {
	0% {
		max-height: 150px;
		padding: 8px;
		opacity: 1;
	}
	100% {
		max-height: 0;
		padding: 0;
		opacity: 0;
	}
}
